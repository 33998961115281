import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const UkgPage = ({ pageContext, location }) => {
  const pageTitle = "UKG"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container">
        <h1>ukg</h1>
      </div>
    </Layout>
  )
}

export default UkgPage
